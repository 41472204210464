// Import SlideShow Images
import kundeservice from '../../../assets/Images/image-kundeservice.png';
import kundeserviceMobile from '../../../assets/Images/image-kundeservice_mobile.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';
import * as ColorGuide from '../../../cssGuides/colorGuide';

// Page Data
const Data = {
  pageName: 'SlideShow Test Page',
  pageTemplate: 'Template1',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'HTML render test page',
    pageDescription: 'Tester component - HTML render',
    bannerImage: null, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>', // accepts null
    bottomContent: [],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    // SLideshow (Zeplin Version : 15/10-14:30)
    {
      componentName: 'SlideShow',
      componentData: {
        header: 'Aktuelle begivenheder',
        manchet: 'Nyheder og updates indenfor pension og forsikring',
      },
      componentChildren: [
        {
          componentName: 'Slider',
          componentData: {
            id: 1,
            source: kundeservice,
            sourceMoblie: kundeserviceMobile,
            type: 'nyhed 1',
            header: 'Laveste antal klager nogensinde hos Sampension',
            manchet:
              'Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef.',
            url: '/test:nyhed_',
          },
        },
        {
          componentName: 'BlockArea',
          componentData: {
            backgroundColor: ColorGuide.aqua,
          },
          componentChildren: [
            {
              componentName: 'HtmlRender',
              componentData: {
                html: '<h2 class="text-center">3 Columns</h2>',
              },
            },
            {
              componentName: 'Columns',
              componentData: {
                columns: 3,
                gap: '40',
              },
              componentChildren: [
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html: '<h2 class="text-center">Columns #1</h2>',
                  },
                },
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html: '<h2 class="text-center">Column #2</h2>',
                  },
                },
                {
                  componentName: 'HtmlRender',
                  componentData: {
                    html: '<h2 class="text-center">Column #2</h2>',
                  },
                },
              ],
            },
          ],
        },
        {
          componentName: 'Slider',
          componentData: {
            id: 3,
            source: kundeservice,
            sourceMoblie: kundeserviceMobile,
            type: 'nyhed 3',
            header: 'Laveste antal klager nogensinde hos Sampension',
            manchet:
              'Antallet af kundeklager i Sampension-fællesskabet var sidste år det laveste nogensinde. Det skyldes blandt andet et målrettet arbejde med at tage ved lære af klagesager, forklarer sunheds- og skadeschef.',
            url: '/test:nyhed_',
          },
        },
      ],
    },
  ],
};

export default Data;
